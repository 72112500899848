import React from "react"

export const TitleUnderlineNavbarVerticalOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="267" height="10" viewBox="0 0 267 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.6395 10C44.1194 9.07169 3.69982 10.1081 0.761887 9.82831C-1.63666 9.45317 2.19643 2.84689 4.42283 1.87407C5.20322 1.52436 6.10984 1.47351 8.18705 1.63247C11.4808 1.93131 54.0693 1.33997 54.0693 1.33997C54.6317 1.54344 56.3187 0.856744 56.6285 1.10472C57.604 1.68332 126.634 0.894891 132.521 0.8631C136.09 0.844025 133.267 1.23824 137.468 1.07928C146.419 0.761364 165.929 1.06657 172.711 0.704142C178.14 0.405302 179.31 0.392594 184.52 0.63421C189.845 0.850392 218.158 0.538832 221.394 0.074676C222.186 -0.0524901 222.794 -0.00797814 222.794 0.138263C222.794 0.170054 242.751 0.278142 242.843 0.392591C242.992 0.538832 243.6 0.608769 244.163 0.507036C245.207 0.354437 266.45 -0.198737 266.783 0.227269C267.827 1.44806 264.901 6.64915 262.433 7.91446C259.908 9.26878 246.045 7.74915 234.901 8.56302L183.017 8.64568L171.897 9.15434C170.623 8.71561 165.722 9.82196 162.761 9.03353C161.51 8.72197 152.674 8.67746 150.723 9.00809C149.024 9.26242 103.819 8.62023 99.1827 9.50403C90.9197 8.76647 57.4089 9.82197 49.6395 10Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineNavbarVerticalTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="132" height="10" viewBox="0 0 132 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5409 10C21.8118 9.07169 1.82912 10.1081 0.376663 9.82831C-0.809136 9.45317 1.08587 2.84689 2.18657 1.87407C2.57238 1.52436 3.0206 1.47351 4.04753 1.63247C5.67588 1.93131 26.7309 1.33997 26.7309 1.33997C27.0089 1.54344 27.8429 0.856744 27.9961 1.10472C28.4784 1.68332 62.6056 0.894891 65.5162 0.8631C67.2807 0.844025 65.8849 1.23824 67.9615 1.07928C72.387 0.761364 82.0322 1.06657 85.3854 0.704142C88.069 0.405302 88.6478 0.392594 91.2236 0.63421C93.8562 0.850392 107.853 0.538832 109.453 0.074676C109.845 -0.0524901 110.145 -0.00797814 110.145 0.138263C110.145 0.170054 120.012 0.278142 120.057 0.392591C120.131 0.538832 120.432 0.608769 120.71 0.507036C121.226 0.354437 131.728 -0.198737 131.893 0.227269C132.409 1.44806 130.962 6.64915 129.742 7.91446C128.494 9.26878 121.64 7.74915 116.131 8.56302L90.4803 8.64568L84.9826 9.15434C84.3528 8.71561 81.9301 9.82196 80.4663 9.03353C79.8479 8.72197 75.4791 8.67746 74.5146 9.00809C73.6749 9.26242 51.3263 8.62023 49.0341 9.50403C44.9491 8.76647 28.3819 9.82197 24.5409 10Z" fill="#FFA700" />
    </svg>
  </span>
)
