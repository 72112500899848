import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="220" height="8" viewBox="0 0 220 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.9014 8C36.353 7.25735 3.04854 8.08646 0.627772 7.86265C-1.34856 7.56254 1.80979 2.27751 3.64428 1.49925C4.2873 1.21949 5.03433 1.17881 6.74589 1.30597C9.4598 1.54505 44.5515 1.07198 44.5515 1.07198C45.0148 1.23475 46.4049 0.685396 46.6602 0.883775C47.464 1.34666 104.343 0.715913 109.194 0.69048C112.134 0.67522 109.808 0.99059 113.269 0.863424C120.645 0.609092 136.72 0.853252 142.309 0.563314C146.782 0.324241 147.746 0.314075 152.039 0.507368C156.427 0.680314 179.755 0.431066 182.422 0.0597408C183.074 -0.0419921 183.576 -0.00638251 183.576 0.11061C183.576 0.136044 200.02 0.222513 200.095 0.314073C200.218 0.431066 200.719 0.487015 201.183 0.405629C202.043 0.283549 219.547 -0.15899 219.821 0.181815C220.681 1.15845 218.27 5.31932 216.237 6.33157C214.157 7.41502 202.734 6.19932 193.552 6.85041L150.801 6.91654L141.638 7.32347C140.588 6.97249 136.55 7.85756 134.111 7.22682C133.08 6.97757 125.799 6.94196 124.191 7.20647C122.792 7.40994 85.5438 6.89618 81.7236 7.60323C74.9151 7.01318 47.3032 7.85757 40.9014 8Z" fill="#FFA700"/>
      </svg>
  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="178" height="8" viewBox="0 0 178 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.093 8C29.4129 7.25735 2.46655 8.08646 0.507925 7.86265C-1.09111 7.56254 1.46428 2.27751 2.94855 1.49925C3.46881 1.21949 4.07323 1.17881 5.45804 1.30597C7.65384 1.54505 36.0462 1.07198 36.0462 1.07198C36.4211 1.23475 37.5458 0.685396 37.7524 0.883775C38.4027 1.34666 84.4227 0.715913 88.3476 0.69048C90.727 0.67522 88.8449 0.99059 91.6451 0.863424C97.6128 0.609092 110.619 0.853252 115.141 0.563314C118.76 0.324241 119.54 0.314075 123.014 0.507368C126.564 0.680314 145.438 0.431066 147.596 0.0597408C148.124 -0.0419921 148.529 -0.00638251 148.529 0.11061C148.529 0.136044 161.834 0.222513 161.895 0.314073C161.995 0.431066 162.4 0.487015 162.775 0.405629C163.471 0.283549 177.633 -0.15899 177.855 0.181815C178.551 1.15845 176.6 5.31932 174.955 6.33157C173.272 7.41502 164.03 6.19932 156.601 6.85041L122.011 6.91654L114.598 7.32347C113.748 6.97249 110.482 7.85756 108.508 7.22682C107.674 6.97757 101.782 6.94196 100.482 7.20647C99.3495 7.40994 69.2127 6.89618 66.1218 7.60323C60.6132 7.01318 38.2726 7.85757 33.093 8Z" fill="#FFA700"/>
      </svg>
  </span>
)